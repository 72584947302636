/* Add here all your css styles (customizations) */

body {
    
}

.margin-top {
    margin-top: 7em;
}

spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    background-color: white;
  }
